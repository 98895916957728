export const environment = {
  production: true,
  appVersion: 'v723demo1',
  USERDATA_KEY: 'authf649fc9a5f55',
  isMockEnabled: true,
  apiUrl: 'https://senderapi.mobile.net.sa/api/v1',
  siteKey : '6LedXNUSAAAAABuSDjDAgRXWRGWZebTuMIOYnVqV',
  take : 15,
  pusher: {
    key: '5334d0b3109b8a2b097f',
    cluster: 'eu',
  }
  // apiUrl: 'mysite.com/api'
};
