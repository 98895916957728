import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { SharedService } from "src/app/shared/services/shared.service";
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService,private router: Router,private shared:SharedService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const redirectUrl = this.authService.redirectUrl.value;
    const token = localStorage.getItem('token');
    let isExpired:boolean =  true;
    if(token){
      isExpired = this.shared.tokenExpired(token);
    }
    const user = localStorage.getItem('user');
    if (user && !isExpired) {
      // logged in so return true
      return true;
    }

    // not logged in so redirect to login page with the return url
    localStorage.clear();
    this.router.navigate(["/auth/login"]);

    return false;
  }
}
