import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  showAddSender:BehaviorSubject<boolean> = new BehaviorSubject(false);
  showWhatsAside:BehaviorSubject<boolean> = new BehaviorSubject(false);
  showCrmAside:BehaviorSubject<boolean> = new BehaviorSubject(false);
  showHostAside:BehaviorSubject<boolean> = new BehaviorSubject(false);
  contentFluid:BehaviorSubject<boolean> = new BehaviorSubject(false);
  showServiceMenu:BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor() { }

  tokenExpired(token: string) {
    const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
    return (Math.floor((new Date).getTime() / 1000)) >= expiry;
  }
}