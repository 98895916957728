import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: 'root' })
export class LoginService {
    href = '';
    constructor(
        private httpClient: HttpClient,
    ) {
        this.href = window.location.href;
    }

    registerWithMobile(body) {
        let url = `${environment.apiUrl}/register-request/mobile`
        return this.httpClient.post(url, body)
    }

    resendCodeToMobile(body) {
        let url = `${environment.apiUrl}/register-request/resend-mobile-code`
        return this.httpClient.post(url, body)
    }

    activeMobile(body) {
        let url = `${environment.apiUrl}/register-request/active-mobile`
        return this.httpClient.post(url, body)
    }

    setUserData(body) {
        let url = `${environment.apiUrl}/register-request/store-details`
        return this.httpClient.post(url, body)
    }

    public getIPAddress() {
        return this.httpClient.get("https://iplist.cc/api");
    }

}