import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, of, Subscription } from 'rxjs';
import { map, catchError, switchMap, finalize } from 'rxjs/operators';
import { UserModel } from '../_models/user.model';
import { AuthModel } from '../_models/auth.model';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { LoginService } from "./login.service";
import { AuthHTTPService } from "./auth-http/auth-http.service";

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  // private fields
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;

  // public fields
  currentUser$: Observable<UserModel>;
  isLoading$: Observable<boolean>;
  currentUserSubject: BehaviorSubject<UserModel>;
  isLoadingSubject: BehaviorSubject<boolean>;
  redirectUrl: BehaviorSubject<string> = new BehaviorSubject('');
  mobileId: BehaviorSubject<string> = new BehaviorSubject(null);


  get currentUserValue(): UserModel {
    return this.currentUserSubject.value;
  }

  set currentUserValue(user: UserModel) {
    this.currentUserSubject.next(user);
  }

  constructor(
    private _Login: LoginService,
    private authHttpService: AuthHTTPService,
    private router: Router
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.currentUserSubject = new BehaviorSubject<UserModel>(undefined);
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
  }

  logout() {
    localStorage.removeItem(this.authLocalStorageToken);
    this.router.navigate(['/auth/login'], {
      queryParams: {},
    });
  }

  // private methods
  private setAuthFromLocalStorage(auth: any): boolean {
    // store auth accessToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    if (auth && auth.codeToken) {
      localStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth));
      return true;
    }
    return false;
  }

  private getAuthFromLocalStorage(): any {
    try {
      const authData = JSON.parse(
        localStorage.getItem(this.authLocalStorageToken)
      );
      return authData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  // public methods
  login(form,guestToken): Observable<any> {
    return this.authHttpService.getAccessToken(guestToken).pipe(
      map(res => {
        const result = this.setAuthFromLocalStorage({ codeToken: res?.token, guestToken });
        return result;
      }),
      switchMap(() => this.getUserLoginRegular(form))
    )
  }

  getUserLoginRegular(form): Observable<any> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.codeToken) {
      return of(undefined);
    }
    return this.authHttpService.login(form,auth);
  }

  // ========Register=========

  activeMobile(body,guestToken): Observable<any> {
    return this.authHttpService.getAccessToken(guestToken).pipe(
      map(res => {
        const result = this.setAuthFromLocalStorage({ codeToken: res?.token, guestToken });
        return result;
      }),
      switchMap(() => this.registerWithMobile(body))
    )
  }

  registerWithMobile(body): Observable<any> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.codeToken) {
      return of(undefined);
    }
    return this.authHttpService.registerWithMobile(body,auth);
  }

  resendCodeToMobile(body): Observable<any> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.codeToken) {
      return of(undefined);
    }
    return this.authHttpService.resendCodeToMobile(body,auth);
  }

  verifyCode(body): Observable<any> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.codeToken) {
      return of(undefined);
    }
    return this.authHttpService.activeMobile(body,auth);
  }

  register(body): Observable<any> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.codeToken) {
      return of(undefined);
    }
    return this.authHttpService.setUserData(body,auth);
  }

  // ========Register=========

  verifyLoginWithUser(code) {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.codeToken) {
      return of(undefined);
    }
    return this.authHttpService.verifyLoginWith(code, auth).pipe(
      map((user: { item: UserModel, access_token: string }) => {
        if (user) {
          this.currentUserSubject = new BehaviorSubject<UserModel>(user.item);
          this.setAuthFromLocalStorage({ ...auth, accessToken: user.access_token });
        } else {
          this.logout();
        }
        return user;
      })
    );


  }

  loginWith(platform, guestToken): Observable<any> {
    return this.authHttpService.getAccessToken(guestToken).pipe(
      map(res => {
        const result = this.setAuthFromLocalStorage({ codeToken: res?.token, guestToken, platform });
        return result;
      }),
      switchMap(() => this.getUserLoginPage()),
      catchError((err) => {
        console.error('err', err);
        return of(undefined);
      })
    )
  }


  getUserLoginPage(): Observable<any> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.codeToken) {
      return of(undefined);
    }
    return this.authHttpService.loginWith(auth).pipe(
      catchError((err) => {
        console.error('err', err);
        return of(undefined);
      })
    );
  }

  // public methods
  // To use when refresh token
  public changeUserAuthData(auth: any): boolean {
    // store auth accessToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    if (auth && auth.codeToken) {
      localStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth));
      return true;
    }
    return false;
  }

  getUserProfile() {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.accessToken) {
      return of(undefined);
    }

    return this.authHttpService.getUserProfile(auth.accessToken).pipe(
      map((user: UserModel) => {
        if (user) {
          this.currentUserSubject = new BehaviorSubject<UserModel>(user);
        } else {
          this.logout();
        }
        return user;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
